body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.form-group {
  @apply flex flex-col my-4 w-full;
}

/* .form-group label {
  @apply text-lg;
} */

.form-control {
  @apply border border-gray-200 text-xl p-2 w-full;
}

.sidebar-menu-item {
  @apply text-gray-500 p-2 px-4 flex flex-row mb-2;
}

.sidebar-animation {
  transition: opacity 0.5s ease-in;
}

.profile .profile-menu {
  display: none;
}

.profile:hover .profile-menu {
  display: block;
}

.card {
  @apply w-full bg-white rounded
}

.card-body {
  @apply p-6
}

.card-heading {
  @apply font-bold text-lg mb-2
}


.table {
  @apply w-full text-left font-normal;
}

.table tr th, .table tr td {
  border-bottom: 1px solid #cccccc;
  padding: 8px;
}

.input-group {
  @apply w-full flex flex-col;
}

.input-field {
  @apply p-2 border;
}

.row {
  @apply flex flex-wrap my-2;
}
